/**
 * Различные настройки клиентского приложения
 */

// App name
export const APP_NAME: string = import.meta.env.VITE_APP_NAME || 'App name';
// App version in the form of: <major>.<minor>.<patch>+<build>.<SHA>
export const APP_VERSION: string = import.meta.env.VITE_APP_VERSION || '1.0.666+abcdef7';

// Sentry DSN
export const SENTRY_DSN: string = import.meta.env.VITE_SENTRY_DSN;

// Video conference rooms Websocket API URL
export const VIDEO_JANUS_WS_URL: string = import.meta.env.VITE_VIDEO_JANUS_WS_URL;

// Whether to show loco/series UIs or not (use general domain objects UI instead)
export const SHOW_LOCO_UI = false;

// Devices service API base URL
export const DEVICES_SERVICE_URL: string = import.meta.env.VITE_DEVICES_SERVICE_URL;

// Superset Dashboard URL
export const SUPERSET_DASHBOARD_URL: string = import.meta.env.VITE_SUPERSET_DASHBOARD_URL || '';

export const ENABLE_REPORTS: boolean = import.meta.env.VITE_ENABLE_REPORTS || false;

export const ENABLE_ANSGAR_ID: boolean = import.meta.env.VITE_ENABLE_ANSGAR_ID || false;

export const ML_SERVICE_URL: string = import.meta.env.VITE_ML_SERVICE_URL;
